import React from "react";
import { REWARD_OFFERING } from "../../constants";
import OnboardingPage from "../../common/components/OnboardingPage";
import { getRedeemUrlWithContext } from "../../utils";

function EuropcarMain() {
  const redeemUrl = getRedeemUrlWithContext("europcar");

  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.europcar}
        />
    </>
  );
}

export default EuropcarMain;  