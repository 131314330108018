import React from "react";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";

function Comp1000PrizesMain() {
  const redeemUrl = getRedeemUrlWithContext("comp-1000-prizes");

  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.comp1000prizes}
        />
    </>
  );
}

export default Comp1000PrizesMain;