/** @jsxImportSource @emotion/react */

import React from "react";
import OnboardingCTA from "./OnboardingCTA";
import RewardsButton from "./RewardsButton";
import styled from "@emotion/styled";

const OnboardingStickyContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  z-index: 9999;
`;

const OnboardingStickyContent = styled.div`
  width: 90%;
  align-self: center;
  margin: 4px auto 0 auto;
  font-size: 14px !important;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  gap: 8px; 
`;

const LegalContent = styled.div`
  width: 95% !important;
`;

interface StickyCTAProps {
  legalContent: React.ReactNode;
  ctaText: string;
  ctaAction: () => void;
}

const StickyCTA: React.FC<StickyCTAProps> = ({ legalContent, ctaText, ctaAction }) => {
  return (
    <OnboardingStickyContainer>
      <OnboardingStickyContent>
        <LegalContent>{legalContent}</LegalContent>
        <OnboardingCTA>
          <RewardsButton clickHandler={ctaAction}>{ctaText}</RewardsButton>
        </OnboardingCTA>
      </OnboardingStickyContent>
    </OnboardingStickyContainer>
  );
};

export default StickyCTA;