import React from "react";
import { GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import OnboardingPage from "../../common/components/OnboardingPage";

function CompWinChooseFlyMain() {
  return (
    <>
        <OnboardingPage
          redeemUrl={`https://www.linkt.com.au/campaigns/fly?${
            GLOBAL_CONST.DATA["externalId"] ? "&cid=" + GLOBAL_CONST.DATA["externalId"] : ""
          }`}
          rewardId={REWARD_OFFERING.compwinchoosefly}
        />
    </>
  );
}

export default CompWinChooseFlyMain;
