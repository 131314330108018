import { CLIENT, COUPON_OFFERS, GLOBAL_CONST, REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";
import { RetrieveCoupon } from "../../common/utils/RetrieveCoupon";

function GlobalEscapesMain() {
  const { loading, coupon } = GLOBAL_CONST.CLIENT_ID !== CLIENT.LINKTGO 
    ? RetrieveCoupon(COUPON_OFFERS.globalescapes) 
    : { loading: false, coupon: "" };

  const redeemUrl = getRedeemUrlWithContext("global-escapes");

  return (
    <>
      {!loading && (
          <OnboardingPage
            rewardId={REWARD_OFFERING.globalescapes}
            partnerCfg={GLOBAL_CONST.CLIENT_ID !== CLIENT.LINKTGO ? GLOBAL_CFG["global-escapes"] : undefined}
            redeemUrl={redeemUrl}
            coupon={GLOBAL_CONST.CLIENT_ID !== CLIENT.LINKTGO ? coupon : undefined}
          />
      )}
    </>
  );
}

export default GlobalEscapesMain;