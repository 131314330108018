import parse from "html-react-parser";
import { useState } from "react";
import { fromPublic, replaceGlobalConfigVars } from "../../utils";
import styled from "@emotion/styled";

const CouponContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  outline: 4px dashed #6d7979;
  outline-offset: -2px;
  padding: 10px;
  margin-bottom: 20px;
`;

const CouponLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding-bottom: 10px;
`;

const CouponCode = styled.div`
  font-size: 22px;
  font-weight: 700;
  font-family: RobotoMono;
  color: black;
  margin: 0 auto;
  user-select: text;
`;

const CopyIcon = styled.div`
  position: absolute;
  right: 0;
`;

const CopyText = styled.div`
  font-size: 12px;
  text-align: center;
  margin: 0;
`;

interface CouponBoxProps {
  partnerCfg: { onboarding1: Onboarding; coupon: Coupon; vars: ConfigVars };
  coupon: string;
}

const CouponBox: React.FC<CouponBoxProps> = ({ partnerCfg, coupon }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(coupon);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };
  
  return (
    <div>
      <CouponContainer>
        <CouponLine>
          <CouponCode>{coupon}</CouponCode>
          {partnerCfg.coupon.copyContent && (
            <CopyIcon onClick={handleCopy}>
              {copied ? (
                <img alt="Copy done" src={fromPublic(partnerCfg.coupon.copyContent.copyDoneSvg)}></img>
              ) : (
                <img alt="Copy" src={fromPublic(partnerCfg.coupon.copyContent.copySvg)}></img>
              )}
            </CopyIcon>
          )}
        </CouponLine>
        <CopyText>{parse(replaceGlobalConfigVars(partnerCfg.coupon.couponContent))}</CopyText>
      </CouponContainer>
    </div>
  );
};

export default CouponBox;