import React from "react";
import parse from "html-react-parser";
import { Box, styled } from "@mui/material";
import { fromPublic, replaceGlobalConfigVars } from "../../utils";

type DropdownContentProps = {
  content: string;
  iconUrl?: string;
};

const StyledBox = styled(Box)<{ iconUrl: string }>`
text-align: left;
ul {
   padding-left: 1.5em;
   list-style-type: disc; 
   li {
      margin-bottom: 8px;
   }
}
ol {
   padding-left: 1.5em;
   list-style-type: decimal;
   li {
      margin-bottom: 8px;
   }
}
.icon-list {
   list-style-type: none;
   padding-left: 0;
}
.icon-list li {
   position: relative;
   padding-left: 1.5em;
   background: url("${(props) => props.iconUrl}") no-repeat left 2px;
   background-size: 1em 1em;  
   min-height: 1em;
   margin-bottom: 8px;
}
.icon-list li {
   list-style-type: none;
}
`;


const DropdownContent: React.FC<DropdownContentProps> = ({ content, iconUrl }) => {
   const processedContent = replaceGlobalConfigVars(content);
   return <StyledBox iconUrl={fromPublic(iconUrl)}>{parse(processedContent)}</StyledBox>;
 };
 
  export default DropdownContent;