import { COUPON_OFFERS, REWARD_OFFERING } from "../../constants";
import OnboardingPage from "../../common/components/OnboardingPage";
import { RetrieveCoupon } from "../../common/utils/RetrieveCoupon";

function SuperCheapAutoMain() {
  const { loading, coupon } = RetrieveCoupon(COUPON_OFFERS.supercheapauto);
  const redeemUrl = GLOBAL_CFG["supercheap-auto"].redeem.redeemUrl;

  return (
    <>
      {!loading && (
          <OnboardingPage
            partnerCfg={GLOBAL_CFG["supercheap-auto"]}
            redeemUrl={redeemUrl}
            coupon={coupon}
            rewardId={REWARD_OFFERING.supercheapauto}
          />
      )}
    </>
  );
}

export default SuperCheapAutoMain;