import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Divider from '@mui/material/Divider';
import { HiChevronDown } from 'react-icons/hi';
import { Box } from '@mui/material';

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  disableGutters: true,
  paddingLeft: '0',
  '&:before': {
    display: 'none', 
  },
 }));
 const AccordionSummary = styled((props: AccordionSummaryProps) => (
 <MuiAccordionSummary {...props} />
 ))(({ theme }) => ({
  paddingLeft: '0',
  minHeight: '48px',
  '&.Mui-expanded': {
    minHeight: '48px',
  },
  '& .MuiAccordionSummary-content': {
    margin: '12px 0', 
    '&.Mui-expanded': {
      margin: '12px 0', 
    },
  },
 })); 
 
 const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  marginTop: '-8px',
 }));

interface DropdownProps {
  items: React.ReactNode[];
}

const Dropdown: React.FC<DropdownProps> = ({ items }) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<HiChevronDown />}
          sx={{
            paddingRight: '0',

          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box className="accordion-heading">{items[0]}</Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px' }}>
          {items.slice(1).map((item, index) => (
            <Box key={index}>{item}</Box>
          ))}
        </AccordionDetails>
      </Accordion>
      <Divider />
    </div>
  );
};

export default Dropdown;