import React from "react";
import styled from "@emotion/styled";

const TextContainer = styled.div`
  text-align: left;
  padding-bottom:16px;
  margin-top:16px;
  background-color: #eff2f5;
  overscroll-behavior: none;
`;

const OnboardingContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <TextContainer>{children}</TextContainer>;
};

export default OnboardingContent;