/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { loadedState } from "../../atoms";
import styled from "@emotion/styled";

const BlankDiv = styled.div`
  position: fixed;
  z-index: 200;
  height: 100vh;
  width: 100vw;
  background-color: #eff2f5;
  opacity: 0.5;
`;


function LoadingBlankOverlay() {
  const loaded = useRecoilValue(loadedState);
  return (
    <BlankDiv
      css={css`
        display: ${loaded ? "none" : "block"};
      `}
    ></BlankDiv>
  );
}

export default LoadingBlankOverlay;
