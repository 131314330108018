import OnboardingPage from "../../common/components/OnboardingPage";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function MyCarMain() {
  const redeemUrl = getRedeemUrlWithContext("mycar");

  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.mycar}
        />
    </>
  );
}

export default MyCarMain;