/** @jsxImportSource @emotion/react */

import React from "react";
import Lottie from "lottie-react";
import { fromPublic, getLottieJson } from "../../utils";
import styled from "@emotion/styled";

const StyledOnboardingStep = styled.div`
  background-color: #eff2f5;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  text-align: center;
`;

const ImageContainer = styled.div`

  img {
    width: 100%;
    height: 100%;
  }
`;

interface OnboardingStepProps {
  image: string;
  imageLottie?: string;
  children: React.ReactNode;
}

const OnboardingStep: React.FC<OnboardingStepProps> = ({ image, imageLottie, children }) => {
  return (
    <StyledOnboardingStep>
      <ImageContainer>
        {imageLottie && <Lottie animationData={getLottieJson(fromPublic(imageLottie))} loop={false} />}
        {!imageLottie && <img alt="" src={fromPublic(image)}></img>}
      </ImageContainer>

      {children}
    </StyledOnboardingStep>
  );
};

export default OnboardingStep;