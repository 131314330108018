import { COUPON_OFFERS, REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";
import { RetrieveCoupon } from "../../common/utils/RetrieveCoupon";

function SydneyAirportMain() {
  const { loading, coupon } = RetrieveCoupon(COUPON_OFFERS.sydneyairport);
  const redeemUrl = getRedeemUrlWithContext("sydney-airport");

  return (
    <>
      {!loading && (
          <OnboardingPage
            partnerCfg={GLOBAL_CFG["sydney-airport"]}
            redeemUrl={redeemUrl}
            coupon={coupon}
            rewardId={REWARD_OFFERING.sydneyairport}
          />
      )}
    </>
  );
}

export default SydneyAirportMain;