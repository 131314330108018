/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { HiOutlineExternalLink } from "react-icons/hi";


const ProgressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
`;

const buttonCssPrimary = css`
  position: relative;
  overflow: hidden;
  outline: none;
  border: 2px solid white;
  border-radius: 10px;
  background-color: #14a248;
  cursor: pointer;
  padding: 14px 18px;
  width: 100%;
  color: white;
  font-size: 17px;
  font-weight: 600;
`;

const buttonCssSecondary = css`
  position: relative;
  overflow: hidden;
  outline: none;
  border: 2px solid #14a248;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 14px 18px;
  width: 100%;
  color: #14a248 !important; 
  font-size: 17px;
  font-weight: 600;
`;

interface RewardsButtonProps {
  className?: string;
  loading?: boolean;
  children: React.ReactNode;
  clickHandler: () => void;
  dataUrl?: string;
  dataRedirect?: string;
  dataAnalytics?: string;
  variant?: "primary" | "secondary";
  icon?: string;
}

const RewardsButton: React.FC<RewardsButtonProps> = ({
  className,
  loading,
  children,
  clickHandler,
  dataUrl,
  dataRedirect,
  dataAnalytics,
  variant = "primary",
  icon,
}) => {
  return (
    <button
      className={className}
      data-url={dataUrl || undefined}
      data-redirect={dataRedirect || undefined}
      data-analytics={dataAnalytics || undefined}
      onClick={clickHandler}
      css={variant === "primary" ? buttonCssPrimary : buttonCssSecondary}
    >
      <ProgressWrapper>
        {loading ? (
          <CircularProgress
            size={20}
            thickness={6}
            css={css`
              color: #ffffff;
            `}
          />
        ) : (
          children
        )}
        {icon === "externalLink" ? (
          <div
            css={css`
              position: absolute;
              right: 12px;
            `}
          >
            <HiOutlineExternalLink fontSize={"24px"} opacity={0.5} strokeWidth={1.5} />
          </div>
        ) : (
          <div />
        )}
      </ProgressWrapper>
    </button>
  );
};

export default RewardsButton;