import { REWARD_OFFERING} from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";
import OnboardingPage from "../../common/components/OnboardingPage";

function StarCarWashMain() {
  const redeemUrl = getRedeemUrlWithContext("star-car-wash");

  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.starcarwash}
        />
    </>
  );
}

export default StarCarWashMain;
