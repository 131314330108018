import OnboardingPage from "../../common/components/OnboardingPage";
import { REWARD_OFFERING } from "../../constants";
import { getRedeemUrlWithContext } from "../../utils";

function SecureParkingMain() {
  const redeemUrl = getRedeemUrlWithContext("secure-parking");
  
  return (
    <>
        <OnboardingPage
          redeemUrl={redeemUrl}
          rewardId={REWARD_OFFERING.secureparking}
        />
    </>
  );
}

export default SecureParkingMain;