import { useEffect, useState } from "react";
import { RewardsHubServicesApi } from "../api/RewardsHubServicesApi";
import { EventLogger } from "./EventLogger";
import { redirectError } from "../../utils";
import { GLOBAL_CONST } from "../../constants";

export const RetrieveCoupon = (offerId: string) => {
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    async function fetchCoupon() {
      const api = new RewardsHubServicesApi();
      try {
        const coupon = await api.getCoupon({
          accountId: GLOBAL_CONST.DATA["accountId"],
          offerId,
        });
        setCoupon(coupon.couponCode);
      } catch (err) {
        await EventLogger.logErrorEvent("Error occurred while fetching coupon:", err);
        redirectError();
      }
      setLoading(false);
    }

    fetchCoupon();
  }, [offerId]);

  return { loading, coupon };
};