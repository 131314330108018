/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";

const StyledOnboardingCTA = styled.div`
  z-index: 9999;
  position: relative;
  background-color: #eff2f5;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  background-color: white;
`;

interface OnboardingCTAProps {
  children: React.ReactNode;
}

const OnboardingCTA: React.FC<OnboardingCTAProps> = ({ children }) => {
  return (
    <StyledOnboardingCTA>
      {children}
    </StyledOnboardingCTA>
  );
};

export default OnboardingCTA;