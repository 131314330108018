import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { appParamState, barcodeState } from "../../atoms";
import { getApi } from "../../common/api/ApiFactory";
import { EventLogger } from "../../common/utils/EventLogger";
import { GLOBAL_CONST, PLACEHOLDER_BARCODE } from "../../constants";
import {
  redirect,
  redirectError,
  redirectLoaded,
  sendAnalytics,
  wireCallbacks,
} from "../../utils";
import RedeemBarcodeCard from "./RedeemBarcodeCard";
import RedeemHintCard from "./RedeemHintCard";

function Redeem() {
  const { flow } = useRecoilValue(appParamState);
  const [barcode, setBarcode] = useRecoilState(barcodeState);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    wireCallbacks();
  });

  useEffect(() => {
    if (flow === "redeem") {
      (async () => {
        try {
          if (barcode === PLACEHOLDER_BARCODE) {
            const api = getApi(GLOBAL_CONST.CLIENT_ID, GLOBAL_CONST.REGION);
            const barcodeResponse = await api.getCouponForShellRewards();
            setBarcode(barcodeResponse);
          }
          await redirect(`${GLOBAL_CONST.CLIENT_ID}://rewards/max-brightness`);
          redirectLoaded();
          await sendAnalytics("redeemScreenOnLoad");
        } catch (e) {
          await EventLogger.logErrorEvent("Error occurred viewing the Shell barcode", e);
          redirectError();
        }
      })();
    }
  }, [flow, barcode, setBarcode]);

  const toggleShowHint = () => {
    sendAnalytics(showHint ? "redeemTroubleScanningDismiss" : "redeemTroubleScanning");
    setShowHint(!showHint);
  };

  return (
    <div>
      <RedeemBarcodeCard hintOnClick={toggleShowHint} barcode={barcode} />
      <RedeemHintCard hintOnClick={toggleShowHint} showHint={showHint} />
    </div>
  );
}

export default Redeem;