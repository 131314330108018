import deepIterator from "deep-iterator";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useIsMounted } from "usehooks-ts";
import { appParamState } from "./atoms";
import BookingDotComMain from "./bookingdotcom/components/BookingDotComMain";
import LoadingBlankOverlay from "./common/components/LoadingBlankOverlay";
import { EventLogger } from "./common/utils/EventLogger";
import Comp1000PrizesMain from "./comp1000prizes/components/Comp1000PrizesMain";
import { FLOW, GLOBAL_CONST, REWARD_OFFERING } from "./constants";
import EuropcarMain from "./europcar/components/EuropcarMain";
import GlobalEscapesMain from "./globalescapes/components/GlobalEscapesMain";
import MyCarMain from "./mycar/components/MyCarMain";
import MyCarTacticalMain from "./mycar/components/MyCarTacticalMain";
import SecureParkingMain from "./secureparking/components/SecureParkingMain";
import ShellMain from "./shell/components/ShellMain";
import ShellFuelUpMain from "./shellfuelup/components/ShellFuelUpMain";
import StarCarWashMain from "./starcarwash/components/StarCarWashMain";
import { cacheImage, cacheLottie, fromPublic, globalErrorHandler, redirectError } from "./utils";
import CompWinChooseFlyMain from "./compwinchoosefly/components/CompWinChooseFlyMain";
import SuperCheapAutoMain from "./supercheapauto/components/SuperCheapAutoMain";
import EuropcarTacticalMain from "./europcar/components/EuropcarTacticalMain";
import SydneyAirportMain from "./sydneyairport/components/SydneyAirportMain";
import SuperCheapTradeMain from "./supercheapauto/components/SuperCheapTradeMain";

function App() {
  const [appParam, setAppParam] = useRecoilState(appParamState);
  const isMounted = useIsMounted();

  useEffect(() => {
    EventLogger.logInfoEvent("Rewards page launched", {
      // eslint-disable-next-line no-restricted-globals
      queryParams: location.search,
      event: "PageLaunched",
      rewardId: GLOBAL_CONST.REWARDS_ID,
    });
  });

  // Determine the view height so carousel slides can be set correctly. Mostly for Safari on mobile.
  useEffect(() => {
    // Unfortunately because the contents are injected into the SPA, we still need to rely on good old scripting.
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search);
    const flow = (queryParams.get("flow") as FLOW) || GLOBAL_CONST.FLOW;

    // Register global error handler.
    window.onerror = globalErrorHandler;

    // Preload all images to give a better webview (mimic native) experience.
    async function preloadAssets() {
      try {
        const currentRewardCfg = GLOBAL_CFG[GLOBAL_CONST.REWARDS_ID];

        // Get a list of images to preload from the global copy config, this should be most images.
        const imagesToPreload = Array.from(deepIterator(currentRewardCfg, { onlyLeaves: true }))
          .map((e: any) => e._value)
          .filter((e: any) => e.endsWith(".svg"))
          .map((src: any) => fromPublic(src));

        await Promise.all(imagesToPreload.map((src: string) => cacheImage(src)));

        // Preload lottie animations where applicable
        const lottiesToPreload = Array.from(deepIterator(currentRewardCfg, { onlyLeaves: true }))
          .map((e: any) => e._value)
          .filter((e: any) => e.endsWith(".json"))
          .map((src: any) => fromPublic(src));

        await Promise.all(lottiesToPreload.map((url: string) => cacheLottie(url)));

        // Store params into atom store.
        setAppParam({ flow });
      } catch (e) {
        console.error("Fatal error occurred", e);
        await EventLogger.logErrorEvent("Fatal error occurred preloading images.", e);
        redirectError();
      }
    }

    preloadAssets();
  }, [isMounted, setAppParam]);

  // set the reward offering id as defined in the last path segment.
  const rewardIdStr = useLocation().pathname.split("/").pop();
  GLOBAL_CONST.REWARDS_ID = rewardIdStr === "" ? GLOBAL_CONST.REWARDS_ID : (rewardIdStr as REWARD_OFFERING);

  return (
    <>
      <LoadingBlankOverlay />
      {/* Important not to load any child component when there are pending resources loading */}
      {appParam.flow === FLOW.PENDING ? (
        <></>
      ) : (
        <Routes>
          {/* Default to Shell */}
          <Route path="/" element={<ShellMain />} />
          <Route path="/europcar" element={<EuropcarMain />} />
          <Route path="/europcar-tactical" element={<EuropcarTacticalMain />} />
          <Route path="/rewards-shell-express" element={<ShellMain />} />
          <Route path="/fuel-up-your-savings" element={<ShellFuelUpMain />} />
          <Route path="/star-car-wash" element={<StarCarWashMain />} />
          <Route path="/booking-dot-com" element={<BookingDotComMain />} />
          <Route path="/comp-1000-prizes" element={<Comp1000PrizesMain />} />
          <Route path="/comp-winchoosefly" element={<CompWinChooseFlyMain />} />
          <Route path="/secure-parking" element={<SecureParkingMain />} />
          <Route path="/mycar" element={<MyCarMain />} />
          <Route path="/mycar-tactical" element={<MyCarTacticalMain />} />
          <Route path="/global-escapes" element={<GlobalEscapesMain />} />
          <Route path="/supercheap-auto" element={<SuperCheapAutoMain />} />
          <Route path="/supercheap-trade" element={<SuperCheapTradeMain />} />
          <Route path="/sydney-airport" element={<SydneyAirportMain />} />
        </Routes>
      )}
    </>
  );
}

export default App;
