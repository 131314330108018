import React from "react";
import { REWARD_OFFERING } from "../../constants";
import OnboardingPage from "../../common/components/OnboardingPage";
import { getRedeemUrlWithContext } from "../../utils";



function EuropcarTacticalMain() {
    const redeemUrl = getRedeemUrlWithContext("europcar-tactical");

    return (
    <>
            <OnboardingPage
                redeemUrl={redeemUrl}
                rewardId={REWARD_OFFERING.europcartactical}
            />
    </>
    );
}

export default EuropcarTacticalMain;
